import axios from 'axios';
import { jwtDecode } from 'jwt-decode';  // Import named export
 // Importación correcta de jwtDecode

const getUserFromToken = (token) => {
  if (!token) return null;  // Si no hay token, retorna null
  try {
    const decodedToken = jwtDecode(token);

    return {
      decodedToken,
      correo: decodedToken.correo,  // Extrae correo del token
      rol: decodedToken.rol,  // Extrae el rol del token
      // Puedes agregar más datos si están presentes en el token
    };
  } catch (error) {
    console.error("Token inválido:", error);  // Maneja el error si el token es inválido
    return null;  // Retorna null si el token no es válido
  }
};

const login = async (credentials) => {
  try {
    // Realiza la solicitud POST al backend para autenticar al usuario
    const response = await axios.post('https://www.recargacreditos.com.mx/api/auth/login', credentials);
    
    // Extrae el token de la respuesta
    const { token } = response.data;

    // Guarda el token en el almacenamiento local
    localStorage.setItem('token', token);
    
    // Decodifica el token para obtener los datos del usuario (correo, rol, etc.)
    const user = getUserFromToken(token);
    
    return user;  // Devuelve los datos del usuario al frontend
  } catch (error) {
    throw new Error('Error en el inicio de sesión');
  }
};

const logout = () => {
  // Elimina el token de localStorage al cerrar sesión
  localStorage.removeItem('token');
  localStorage.removeItem('token_admin'); // Limpia el token de administrador si existe

};

const authService = {
  login,
  logout,
  getUserFromToken,
};

export default authService;
