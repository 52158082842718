import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col, OverlayTrigger, Tooltip,Alert, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';
import { FaAddressBook, FaCheck, FaDollarSign } from 'react-icons/fa';
import axios from 'axios';
import './ToggleSwitch.css';
import { useNavigate } from 'react-router-dom';
import api from '../../services/axiosConfig';

// ToggleSwitch component
const ToggleSwitch = ({ id, checked, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <span className="slider round"></span>
    </label>
  );
};



const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [originalStates, setOriginalStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+52');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [userName, setUserName] = useState('');
  const [abonoValues, setAbonoValues] = useState({});
  const [saldoValues, setSaldoValues] = useState({});
  const abonoInputRefs = useRef({});
  const saldoInputRefs = useRef({});
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Para el modal de confirmación
  const [confirmAction, setConfirmAction] = useState({ nombre_tienda: '',id: '', activar: false });
  const [nombreTienda, setNombreTienda] = useState('');

  // Función para obtener usuarios desde el backend
  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/admin/obtener-tiendas/usuario', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
      setTotalUsers(response.data.length);
    } catch (error) {
      console.error('Error al obtener los usuarios', error);
    }
  };

  // Obtener el nombre del usuario desde el token
  const fetchUserName = async () => {
    const token = localStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const nombre = decodedToken.nombres_apellidos;
    setUserName(nombre || 'Usuario');
  };

  // Obtener la ubicación (latitud y longitud) del navegador
  const fetchGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error al obtener la geolocalización', error);
        }
      );
    } else {
      console.error('La geolocalización no es soportada por este navegador.');
    }
  };

  useEffect(() => {
    if (!showModal) {
      setNombreTienda('');
      setPhoneNumber('');
      setCountryCode('+52');
      setMessage({ type: '', text: '' });
    }
  }, [showModal]);

  useEffect(() => {
    fetchUsers();
    fetchUserName();
    fetchGeolocation(); // Obtener geolocalización al cargar el componente
  }, []);

  // Función para restaurar el estado activo original
  const restoreOriginalState = (id) => {
    const restoredActivo = originalStates[id];
    if (restoredActivo !== undefined) {
      updateUserState(id, restoredActivo); // Restaurar el estado original
    }
    setSelectedId(null);
  };

  const updateUserState = async (id, activo) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(
        `https://www.recargacreditos.com.mx/api/admin/editar-tienda2/${id}`,
        { activo },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error al actualizar el estado del usuario', error);
    }
  };

  const handleFieldClick = (id, user) => {
    const isSelected = selectedId === id;

    if (selectedId && selectedId !== id) {
      // Restaurar el estado del usuario previamente seleccionado
      const previousUser = users.find((u) => u.usuario.id === selectedId);
      if (previousUser) {
        restoreOriginalState(previousUser.usuario.id);
      }
    }

    const originalActivo = user.usuario.activo;

    if (!isSelected) {
      setOriginalStates((prevState) => ({
        ...prevState,
        [id]: originalActivo,
      }));
      setSelectedId(id);
      updateUserState(user.usuario.id, false); // Desactivar temporalmente mientras se selecciona
    }
  };

  // Manejar el clic en el ToggleSwitch
  const handleToggle = (nombre_tienda, id, currentState) => {
    if (selectedId === id) {
      restoreOriginalState(id); // Deselecciona la fila si está seleccionada
    }
    setConfirmAction({ nombre_tienda, id, activar: !currentState }); // Cambiar el estado al opuesto
    setShowConfirmModal(true); // Mostrar modal para confirmación
  };

  // Función para confirmar la acción en el modal
  const confirmToggleAction = async () => {
    const { id, activar } = confirmAction;
    await updateUserState(id, activar); // Actualizar el estado en la base de datos
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.usuario.id === id
          ? { ...user, usuario: { ...user.usuario, activo: activar } }
          : user
      )
    );
    setShowConfirmModal(false); // Cerrar modal de confirmación
  };

  // Función para manejar clics fuera de la fila seleccionada
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target) && selectedId !== null) {
      const user = users.find((user) => user.usuario.id === selectedId);
      if (user) {
        restoreOriginalState(user.usuario.id); // Restaurar el estado al hacer clic fuera
      }
    }
  };

  const handleBeforeUnload = (event) => {
    if (selectedId !== null) {
      const user = users.find((user) => user.usuario.id === selectedId);
      if (user) {
        restoreOriginalState(user.usuario.id); // Restaurar estado al cerrar la página
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedId, originalStates]); // Asegúrate de que los efectos dependan de los estados correctos

  // Función para evitar que se active la lógica de clic cuando interactúas con los botones internos
  const handleButtonClick = (e, id, route) => {
    e.stopPropagation(); // Detener propagación para que no afecte la selección de la fila
    restoreOriginalState(id); // Restaurar el estado antes de navegar
    setTimeout(() => {
      navigate(route); // Navegar al enlace configurado después de restaurar el estado
    }, 0); // Asegurarse de que el estado se restaure antes de navegar
  };

  // Función para manejar el cambio de abono (solo números positivos)
  const handleAbonoChange = (id, value) => {
    const abono = Math.max(0, parseFloat(value)); // Asegurarse de que sea positivo
    setAbonoValues((prevState) => ({ ...prevState, [id]: abono }));
  };

  const handleSaldoChange = (id, value) => {
    setSaldoValues((prevState) => ({ ...prevState, [id]: parseFloat(value) }));
  };

  const handleAcreditarAbono = async (id) => {
    const token = localStorage.getItem('token');
    const valor = Math.max(0, abonoValues[id]); // Asegurarse de que el abono sea positivo
    if (!isNaN(valor) && valor > 0) {
      try {
        const response = await axios.post(
          `https://www.recargacreditos.com.mx/api/admin/acreditar-saldo2/${id}`,
          {
            valor: valor, // Abono en negativo
            credito: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { tiendaSaldoActual, valorAdeudado } = response.data; // Extraer los valores de la respuesta

        // Actualizar el estado local para reflejar el nuevo saldo y pendiente
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.usuario.id === id
              ? {
                  ...user,
                  saldo: tiendaSaldoActual, // Mostrar tiendaSaldoActual como saldo
                  credito: valorAdeudado, // Mostrar valorAdeudado como pendiente
                }
              : user
          )
        );

        setMessage({ type: 'success', text: 'Abono acreditado exitosamente' });
        setAbonoValues((prevState) => ({ ...prevState, [id]: '' }));
        restoreOriginalState(id);
      } catch (error) {
        setMessage({ type: 'error', text: 'No se pudo acreditar el abono' });
        console.error('Error al acreditar el abono', error);
      }
    }
  };

  const handleAcreditarSaldo = async (id) => {
    const token = localStorage.getItem('token');
    const valor = saldoValues[id];
    if (!isNaN(valor)) {
      try {
        const response = await axios.post(
          `https://www.recargacreditos.com.mx/api/admin/acreditar-saldo2/${id}`,
          {
            valor,
            credito: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { tiendaSaldoActual, valorAdeudado } = response.data; // Extraer los valores de la respuesta

        // Actualizar el estado local para reflejar el nuevo saldo y pendiente
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.usuario.id === id
              ? {
                  ...user,
                  saldo: tiendaSaldoActual, // Mostrar tiendaSaldoActual como saldo
                  credito: valorAdeudado, // Mostrar valorAdeudado como pendiente
                }
              : user
          )
        );

        setMessage({ type: 'success', text: 'Saldo acreditado exitosamente' });
        setSaldoValues((prevState) => ({ ...prevState, [id]: '' }));
        restoreOriginalState(id);
      } catch (error) {
        setMessage({ type: 'error', text: 'No se pudo acreditar el saldo' });
        console.error('Error al acreditar el saldo', error);
      }
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event, id, isAbono) => {
    if (event.key === 'Enter') {
      if (isAbono) {
        handleAcreditarAbono(id);
      } else {
        handleAcreditarSaldo(id);
      }
      if (isAbono) {
        abonoInputRefs.current[id].blur();
      } else {
        saldoInputRefs.current[id].blur();
      }
    }
  };

  // Control de nombre de tienda: solo permite letras, números y espacios
  const handleNombreTiendaInput = (e) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9 ]*$/; // Solo letras, números y espacios
    const input = e.target.value;

    if (regex.test(input)) {
      setNombreTienda(input);
      setMessage({ type: '', text: '' }); // Limpiar el mensaje si todo es válido
    } else {
      setMessage({ type: 'error', text: 'No se permiten caracteres especiales en el nombre de la tienda.' });
    }
  };

  const handleCreateTienda = async () => {
    const celular = `${countryCode}${phoneNumber}`;
    const token = localStorage.getItem('token');

    // Validación para el celular
    const regexCelular = /^\+?[1-9]\d{1,14}$/;

    if (!nombreTienda) {
      setMessage({ type: 'error', text: 'El nombre de la tienda no puede estar vacío.' });
      return;
    }

  
    if (phoneNumber.length !== 10) {
      setMessage({ type: 'error', text: 'El número de teléfono debe tener exactamente 10 dígitos.' });
      return;
    }

    if (!celular || !regexCelular.test(celular)) {
      setMessage({ type: 'error', text: 'El número de celular no es válido.' });
      return;
    }

    if (!latitude || !longitude) {
      setMessage({ type: 'error', text: 'Debes permitir acceso a la ubicación para agregar la tienda.' });
      return;
    }

    try {
      await axios.post(
        'https://www.recargacreditos.com.mx/api/admin/crear-por-tienda',
        {
          nombre_tienda: nombreTienda,
          celular,
          latitud: latitude,
          longitud: longitude,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Tienda creada exitosamente' });
      setShowModal(false);
      setNombreTienda('');
      setPhoneNumber('');
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo crear la tienda. Verifica los datos ingresados.' });
      console.error('Error al crear la tienda', error);
    }
  };

      // Nueva función para manejar la entrada del número de teléfono
      const handlePhoneNumberInput = (e) => {
        const input = e.target.value;
        const numbersOnly = input.replace(/\D/g, '');
        const truncatedInput = numbersOnly.slice(0, 10);
        setPhoneNumber(truncatedInput);
      };
    

  // Filtro por nombre de tienda
  const filteredUsers = users.filter(user => {
    const nameMatch = user.usuario.nombre_tienda ? user.usuario.nombre_tienda.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    return nameMatch;
  });

  return (
    <Container ref={containerRef}>
      <Row className="my-2 align-items-center">
        <Col xs={8} sm={10} className="mb-1">
          <h1 className="text-left" style={{ color: '#0A74DA', marginBottom: '0.5rem' }}>Gestionar Usuarios</h1>
        </Col>
        <Col xs={4} sm={2} className="text-right pr-2 mb-1">
          <Button
            variant="primary"
            style={{ backgroundColor: '#0A74DA', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => setShowModal(true)}
          >
            <FaCheck style={{ marginRight: '5px' }} /> Nuevo
          </Button>
        </Col>

        <Col xs={12} className="mt-2 mb-1">
          <h4 className="text-left" style={{ marginBottom: '0.4rem' }}>Bienvenido, {userName}</h4>
          <p className="text-left text-muted" style={{ fontSize: '0.9rem', marginBottom: '0.2rem' }}>
            Tu número de usuarios registrados es: {totalUsers}
          </p>
        </Col>
      </Row>

      {message.text && (
        <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
          {message.text}
        </Alert>
      )}

      <InputGroup className="mb-3">
        <FormControl
          placeholder="Buscar por nombre de tienda"
          onChange={handleSearch}
        />
      </InputGroup>

      <Table hover className="custom-table d-none d-md-table">
        <thead>
          <tr>
            <th>Nombre de Tienda</th>
            <th>Saldo</th>
            <th>Congelar</th>
            <th>Pendiente</th>
            <th>Abono</th>
            <th>+Saldo</th>
            <th>Promedio</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr
              key={user.usuario.id}
              className={`${selectedId === user.usuario.id ? 'selected-row' : ''} ${user.contado ? 'contado-row' : ''}`}
              >
              <td>
                <a
                  href={`https://www.google.com/maps?q=${user.latitud},${user.longitud}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user.usuario.nombre_tienda}
                </a>
              </td>
              <td>{user.saldo.toFixed(2)}</td>
              <td>
                <ToggleSwitch
                  id={`activo-switch-${user.usuario.id}`}
                  checked={user.usuario.activo}
                  onChange={() => handleToggle(user.usuario.nombre_tienda, user.usuario.id, user.usuario.activo)}
                />
              </td>

              <td>{user.credito}</td>
              <td>
                <Form.Control
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="Ingrese abono"
                  value={abonoValues[user.usuario.id] || ''}
                  onChange={(e) => handleAbonoChange(user.usuario.id, e.target.value)}
                  onClick={() => handleFieldClick(user.usuario.id, user)}
                  onKeyPress={(e) => handleKeyPress(e, user.usuario.id, true)}
                  ref={(el) => (abonoInputRefs.current[user.usuario.id] = el)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  placeholder="Ingrese saldo"
                  value={saldoValues[user.usuario.id] || ''}
                  onChange={(e) => handleSaldoChange(user.usuario.id, e.target.value)}
                  onClick={() => handleFieldClick(user.usuario.id, user)}
                  onKeyPress={(e) => handleKeyPress(e, user.usuario.id, false)}
                  ref={(el) => (saldoInputRefs.current[user.usuario.id] = el)}
                />
              </td>
              <td>{user.promedioSemanal}</td>
              <td>
              <ButtonGroup>
      {/* Botón con Tooltip para FaDollarSign */}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-top`}>Historial</Tooltip>}
      >
        <Button variant="link" onClick={(e) => handleButtonClick(e, user.usuario.id, `/vendedor/usuario/${user.usuario.id}`)}>
          <FaDollarSign />
        </Button>
      </OverlayTrigger>

      {/* Botón con Tooltip para FaAddressBook */}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-top`}>Configuración</Tooltip>}
      >
        <Button variant="link" onClick={(e) => handleButtonClick(e, user.usuario.id, `/vendedor/usuario-configuracion/${user.id}`)}>
          <FaAddressBook />
        </Button>
      </OverlayTrigger>
    </ButtonGroup>

                     <div className="user-detail">
           
              </div>
              
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Nueva Tienda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.text && (
          <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
            {message.text}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formNombreTienda">
            <Form.Label>Nombre de la Tienda</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa el nombre de la tienda"
              value={nombreTienda}
              onChange={handleNombreTiendaInput}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Número de WhatsApp</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Control
                  as="select"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="+52">🇲🇽 +52 México</option>
                  <option value="+1">🇺🇸 +1 USA</option>
                  <option value="+34">🇪🇸 +34 España</option>
                </Form.Control>
              </Col>
              <Col md={8}>
                <Form.Control
                  type="tel"
                  placeholder="Ingresa el número de teléfono"
                  value={phoneNumber}
                  onChange={handlePhoneNumberInput} // Usamos la nueva función
                  required
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: '#0A74DA', color: '#fff' }}
          onClick={handleCreateTienda}
        >
          Crear Tienda
        </Button>
      </Modal.Footer>
    </Modal>
  

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmAction.activar ? (
            <p>
              ¿Seguro que quieres habilitar a <strong>{confirmAction.id}</strong>?
            </p>
          ) : (
            <p>
              ¿Seguro que quieres congelar a <strong>{confirmAction.id}</strong>?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={confirmToggleAction}>
            {confirmAction.activar ? 'Habilitar' : 'Congelar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-md-none user-cards">
        {filteredUsers.map((user) => (
          <div
            key={user.usuario.id}
            className={`user-card ${selectedId === user.usuario.id ? 'selected-row' : ''} ${user.contado ? 'contado-row' : ''}`}
            >
            <div className="user-row">
              <div className="user-detail">
                <strong>Tienda:</strong> {user.usuario.nombre_tienda}
              </div>
              <div className="user-detail">
                <strong>Saldo Actual:</strong> {user.saldo.toFixed(2)}
              </div>
              <div className="user-detail">
                <strong>Congelar:</strong>
                <br />
                <ToggleSwitch
                  id={`activo-switch-${user.usuario.id}`}
                  checked={user.usuario.activo}
                  onChange={() => handleToggle(user.usuario.nombre_tienda, user.usuario.id, user.usuario.activo)}
                />
              </div>
            </div>
            <div className="user-row">
              <div className="user-detail">
                <strong>Pendiente:</strong> {user.credito}
              </div>
              <div className="user-detail">
                <Form.Control
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="Abono"
                  value={abonoValues[user.usuario.id] || ''}
                  onChange={(e) => handleAbonoChange(user.usuario.id, e.target.value)}
                  onClick={() => handleFieldClick(user.usuario.id, user)}
                  onKeyPress={(e) => handleKeyPress(e, user.usuario.id, true)}
                  ref={(el) => (abonoInputRefs.current[user.usuario.id] = el)}
                />
              </div>
              <div className="user-detail">
                <Form.Control
                  type="number"
                  placeholder="Saldo"
                  value={saldoValues[user.usuario.id] || ''}
                  onChange={(e) => handleSaldoChange(user.usuario.id, e.target.value)}
                  onClick={() => handleFieldClick(user.usuario.id, user)}
                  onKeyPress={(e) => handleKeyPress(e, user.usuario.id, false)}
                  ref={(el) => (saldoInputRefs.current[user.usuario.id] = el)}
                />
              </div>
            </div>
            <div className="user-row">
              <div className="user-detail">
                <strong>Promedio:</strong> {user.promedioSemanal}
              </div>
              <div className="user-detail">
                <Button
                  variant="link"
                  onClick={(e) => handleButtonClick(e, user.usuario.id, `/vendedor/usuario/${user.usuario.id}`)}
                >
                  <FaDollarSign /> Historial
                </Button>
              </div>
              <div className="user-detail">
                <Button
                  variant="link"
                  onClick={(e) => handleButtonClick(e, user.usuario.id, `/vendedor/usuario-configuracion/${user.id}`)}
                >
                  <FaAddressBook /> Configuración
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .custom-table {
          border-collapse: collapse;
          width: 100%;
        }
        .custom-table thead th {
          border-bottom: 1px solid #ddd;
        }
        .custom-table tbody tr {
          transition: background-color 0.3s ease;
        }
        .custom-table tbody tr:hover {
          background-color: #f1f1f1;
        }
        .custom-table tbody tr:nth-child(odd) {
          background-color: #f9f9f9;
        }
        .custom-table tbody tr:nth-child(even) {
          background-color: #ffffff;
        }
        .custom-table td,
        .custom-table th {
          border: none;
          padding: 12px 15px;
        }
        .selected-row {
          background-color: #f7bd56 !important;
        }
        .contado-row {
          background-color: #ffdddd !important;  /* Un rojo claro */
        }
          .selected-row.contado-row {
  background-color: #f7bd56 !important;
}

        .user-cards {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        .user-card {
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          background-color: #fff;
          transition: background-color 0.3s ease;
        }

        .user-card.selected-row {
          background-color: #f7bd56;
        }

        .user-row {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
        }

        .user-detail {
          flex: 1;
          text-align: left;
        }

        .user-detail:nth-child(2) {
          text-align: center;
        }

        .user-detail:nth-child(3) {
          text-align: right;
        }
      


        @media (max-width: 1000px) {
          .custom-table {
            display: none;
          }
        }
      `}</style>
    </Container>
  );
};

export default ManageUsers;
