import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col, Alert, InputGroup, FormControl, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';
import { FaCheck, FaTimes, FaPlus, FaDollarSign, FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import './ToggleSwitch.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Importa el contexto de autenticación

const ToggleSwitch = ({ id, checked, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <span className="slider round"></span>
    </label>
  );
};

const ManageSellers = () => {
  const [users, setUsers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [nombres_apellidos, setNombresApellidos] = useState('');
  const [password, setPassword] = useState('');
  const [countryCode, setCountryCode] = useState('+52');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSaldoModal, setShowSaldoModal] = useState(false);
  const [valor, setValor] = useState('');
  const [porcentaje, setPorcentaje] = useState(4);
  const navigate = useNavigate();
  const [actingAsSeller, setActingAsSeller] = useState(false);
  const { updateUserRole } = useContext(AuthContext);
  const [editingSaldoIndex, setEditingSaldoIndex] = useState(null);
  const [editableSaldoValue, setEditableSaldoValue] = useState('');
  
  const [editableValue, setEditableValue] = useState('');
  const [editType, setEditType] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // Nuevas variables de estado para los campos editables
  const [editedEmail, setEditedEmail] = useState('');
  const [editedUsuarioPorcentaje, setEditedUsuarioPorcentaje] = useState('');
  const [editedPorcentaje, setEditedPorcentaje] = useState('');
  const [usuarioPorcentajeError, setUsuarioPorcentajeError] = useState('');
  const [porcentajeError, setPorcentajeError] = useState('');

  // Validación de email
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  // Efecto para actualizar los campos cuando se selecciona un usuario
  useEffect(() => {
    if (selectedUser) {
      setEditedEmail(selectedUser.usuario.correo);
      setEditedUsuarioPorcentaje(selectedUser.usuario.porcentaje);
      setEditedPorcentaje(selectedUser.porcentaje);
    }
  }, [selectedUser]);

  // Manejo de cambios y validaciones en tiempo real
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEditedEmail(value);

    if (validateEmail(value)) {
      setEmailError('');
    } else {
      setEmailError('El correo electrónico no es válido');
    }
  };

  const handleUsuarioPorcentajeChange = (e) => {
    const value = e.target.value;
    setEditedUsuarioPorcentaje(value);

    const numValue = parseFloat(value);
    if (isNaN(numValue) || numValue < 0 || numValue > 2) {
      setUsuarioPorcentajeError('El porcentaje debe estar entre 0 y 2');
    } else {
      setUsuarioPorcentajeError('');
    }
  };

  const handlePorcentajeChange = (e) => {
    const value = e.target.value;
    setEditedPorcentaje(value);

    const numValue = parseFloat(value);
    if (isNaN(numValue) || numValue < 0 || numValue > 7) {
      setPorcentajeError('El porcentaje debe estar entre 0 y 7');
    } else {
      setPorcentajeError('');
    }
  };

  // Métodos para enviar los datos al backend
  const handleEmailSubmit = async () => {
    if (emailError) {
      return;
    }
    const token = localStorage.getItem('token');
    try {
      await axios.put(
        `https://www.recargacreditos.com.mx/api/admin/editar-correo/${selectedUser.usuario.id}`,
        {
          correo: editedEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Correo actualizado exitosamente' });
      setSelectedUser((prev) => ({
        ...prev,
        usuario: {
          ...prev.usuario,
          correo: editedEmail,
        },
      }));
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo actualizar el correo' });
      console.error('Error al actualizar el correo', error);
    }
  };

  const handleUsuarioPorcentajeSubmit = async () => {
    if (usuarioPorcentajeError) {
      return;
    }
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `https://www.recargacreditos.com.mx/api/admin/actualizar-porcentaje-usuario`,
        {
          usuarioId:selectedUser.usuario.id, porcentaje: parseFloat(editedUsuarioPorcentaje),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Porcentaje del usuario actualizado exitosamente' });
      setSelectedUser((prev) => ({
        ...prev,
        usuario: {
          ...prev.usuario,
          porcentaje: parseFloat(editedUsuarioPorcentaje),
        },
      }));
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo actualizar el porcentaje del usuario' });
      console.error('Error al actualizar el porcentaje del usuario', error);
    }
  };

  const handlePorcentajeSubmit = async () => {
    if (porcentajeError) {
      return;
    }
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `https://www.recargacreditos.com.mx/api/admin/actualizar-porcentaje`,
        {
          tiendaId:selectedUser.id, porcentaje: parseFloat(editedPorcentaje),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Porcentaje actualizado exitosamente' });
      setSelectedUser((prev) => ({
        ...prev,
        porcentaje: parseFloat(editedPorcentaje),
      }));
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo actualizar el porcentaje' });
      console.error('Error al actualizar el porcentaje', error);
    }
  };





  const validateName = (value) => {
    const nameRegex = /^[A-Za-zÀ-ÿ\s]+$/;
    return nameRegex.test(value);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };

  // Validar el formulario en tiempo real
  const [formIsValid, setFormIsValid] = useState(false);


  // Fetch users and geolocation on component mount
  useEffect(() => {
    fetchUsers();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error al obtener la geolocalización', error);
        }
      );
    }
  }, []);

  // Fetch users from backend
  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://www.recargacreditos.com.mx/api/admin/obtener-vendedor', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error al obtener los usuarios', error);
    }
  };

  // Handle saldo editing
  const handleSaldoClick = (index) => {
    setEditingSaldoIndex(index);
    setEditableSaldoValue('');
  };

  const handleSaldoChange = (e) => {
    setEditableSaldoValue(e.target.value);
  };

  const handleSaldoBlur = async (user) => {
    if (editableSaldoValue.trim() === '') {
      setEditingSaldoIndex(null);
      return;
    }
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `https://www.recargacreditos.com.mx/api/admin/acreditar-saldo2/${user.usuario.id}`,
        {
          valor: parseFloat(editableSaldoValue),
          porcentaje: 4,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setMessage({ type: 'success', text: 'Saldo actualizado exitosamente' });
      setEditingSaldoIndex(null);
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo actualizar el saldo' });
      console.error('Error al actualizar el saldo', error);
      setEditingSaldoIndex(null);
    }
  };

  const handleSaldoKeyDown = (e, user) => {
    if (e.key === 'Enter') {
      handleSaldoBlur(user);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Toggle active state
  const handleToggle = async (index, field) => {
    const token = localStorage.getItem('token');
    const user = users[index];
    const previousState = { ...user };
    try {
      user.usuario[field] = !user.usuario[field];
      setUsers([...users]);
      await axios.put(
        `https://www.recargacreditos.com.mx/api/admin/editar-tienda2/${user.usuario.id}`,
        {
          [field]: user.usuario[field],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: `El campo ${field} ha sido actualizado` });
    } catch (error) {
      setUsers((prevUsers) => {
        prevUsers[index] = previousState;
        return [...prevUsers];
      });
      setMessage({ type: 'error', text: `No se pudo actualizar el campo ${field}` });
      console.error(`Error al actualizar el campo ${field}`, error);
    }
  };

  // Handle editing of deposit and recharge values
  const handleEditClick = (index, type) => {
    setEditingIndex(index);
    setEditType(type);
    setEditableValue('');
  };

  const handleValueChange = (e) => {
    setEditableValue(e.target.value);
  };
  const handleRowClick = (event, rowIndex) => {
    // Verifica si el clic fue dentro de una celda editable
    if (
      editingIndex !== null &&
      editingIndex !== rowIndex
    ) {
      // Si se hace clic en otra celda o fuera del campo de edición, restaura la vista predeterminada
      setEditingIndex(null);
      setEditingSaldoIndex(null);
      setEditableValue('');
      setEditableSaldoValue('');
    }
  };
  

  const handleValueKeyDown = (e, user) => {
    if (e.key === 'Enter') {
      handleValueSubmit(user);
    }
  };

  const handleValueSubmit = async (user) => {
    if (editableValue.trim() === '') {
      setEditingIndex(null);
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `https://www.recargacreditos.com.mx/api/admin/pago-vendedor/${user.usuario.correo}`,
        {
          valor: parseFloat(editableValue),
          tipo: editType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: response.data.mensaje });
      setEditingIndex(null);
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'Error al realizar el pago' });
      console.error('Error al realizar el pago', error);
      setEditingIndex(null);
    }
  };

  const handleShowSaldoModal = (user) => {
    setSelectedUser(user);
    setShowSaldoModal(true);
  };

  const handleCloseSaldoModal = () => {
    setSelectedUser(null);
    setShowSaldoModal(false);
  };

  const handleAcreditarSaldo = async () => {
    const token = localStorage.getItem('token');

    try {
      await axios.post(
        `https://www.recargacreditos.com.mx/api/admin/acreditar-saldo2/${selectedUser.usuario.id}`,
        {
          valor: parseFloat(valor),
          porcentaje: parseFloat(porcentaje),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Saldo acreditado exitosamente' });
      setShowSaldoModal(false);
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo acreditar el saldo' });
      console.error('Error al acreditar el saldo', error);
    }
  };

  useEffect(() => {
    if (email && nombres_apellidos && phoneNumber && !emailError && !nameError && !phoneError) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [email, nombres_apellidos, phoneNumber, emailError, nameError, phoneError]);

  const handleCreateUser = async () => {
    const celular = `${countryCode}${phoneNumber}`;
    const token = localStorage.getItem('token');
  
    // Limpiar errores previos
    setEmailError('');
    setNameError('');
    setPhoneError('');
    setMessage({ type: '', text: '' });
  
    if (latitude && longitude) {
      try {
        await axios.post(
          'https://www.recargacreditos.com.mx/api/admin/crear-vendedor',
          {
            correo: email,
            contrasenia: celular,
            celular: celular,
            nombres_apellidos: nombres_apellidos,
            latitud: latitude,
            longitud: longitude,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMessage({ type: 'success', text: 'Usuario creado exitosamente' });
        setShowModal(false);
        setEmail('');
        setPassword('');
        setCountryCode('+52');
        setPhoneNumber('');
        setNombresApellidos('');
        fetchUsers();
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'No se pudo crear el usuario';
  
        // Mostrar el error específico en el campo correspondiente
        if (errorMessage.includes('correo')) {
          setEmailError(errorMessage);
        } else if (errorMessage.includes('celular')) {
          setPhoneError(errorMessage);
        } else {
          setMessage({ type: 'error', text: errorMessage });
        }
        console.error('Error al crear el usuario', error);
      }
    } else {
      setMessage({ type: 'error', text: 'No se pudo obtener la geolocalización.' });
      console.error('No se pudo obtener la geolocalización.');
    }
  
    setTimeout(() => {
      setMessage({ type: '', text: '' });
    }, 5000);
  };
  

  useEffect(() => {
    if (!showModal) {
      // Restablecer los campos del formulario y los errores cuando se cierra el modal
      setEmail('');
      setNombresApellidos('');
      setCountryCode('+52');
      setPhoneNumber('');
      setEmailError('');
      setNameError('');
      setPhoneError('');
    }
  }, [showModal]);

  const actAsSeller = async (user) => {
    const tokenAdmin = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://www.recargacreditos.com.mx/api/auth/actuar_como_vendedor',
        { vendedorId: user.UsuarioId },
        { headers: { Authorization: `Bearer ${tokenAdmin}` } }
      );

      localStorage.setItem('token_admin', tokenAdmin);
      localStorage.setItem('token', response.data.token_vendedor);

      updateUserRole(response.data.token_vendedor);

      setActingAsSeller(true);
      navigate('/vendedor/users');
    } catch (error) {
      console.error('Error al actuar como vendedor', error);
    }
  };

  // Exit editing mode when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditingIndex(null);
        setEditingSaldoIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredUsers = users.filter((user) => {
    const emailMatch = user.usuario.correo ? user.usuario.correo.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const nameMatch = user.usuario.nombres_apellidos ? user.usuario.nombres_apellidos.toLowerCase().includes(searchTerm.toLowerCase()) : false;

    return emailMatch || nameMatch;
  });

  return (
    <Container ref={containerRef}>
      <Row className="my-4">
        <Col>
          <h1 className="text-left" style={{ color: '#0A74DA' }}>Vendedores</h1>
        </Col>
        <Col className="text-right">
          <Button
            variant="primary"
            style={{ backgroundColor: '#0A74DA', color: '#fff' }}
            onClick={() => setShowModal(true)}
          >
            <FaCheck /> Nuevo
          </Button>
        </Col>
      </Row>

      {message.text && (
        <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
          {message.text}
        </Alert>
      )}

      <InputGroup className="mb-3">
        <FormControl
          placeholder="Buscar por correo, nombre o cédula"
          onChange={handleSearch}
        />
      </InputGroup>

      {/* Desktop Table View */}
      <Table hover className="custom-table d-none d-md-table">
        <thead>
          <tr>
            <th>Correo</th>
            <th>Tiendas</th>
            <th>Historial</th>
            <th>Depósitos</th>
            <th>Depósitos recargas</th>
            <th>Saldo</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user, index) => (
            <tr key={index}>
              <td>
                <button
                  onClick={() => actAsSeller(user)}
                  //color: '#0A74DA',
                  style={{  cursor: 'pointer', background: 'none', border: 'none', padding: 0}}
                >
                  {user.usuario.correo}
                </button>
              </td>
              <td>{user.usuario.total_tiendas}</td>
              <td>
                <Button
                  variant="outline-primary"
                  style={{ padding: '5px 10px', fontSize: '14px' }}
                  onClick={() => navigate(`admin/historial/${user.usuario.id}`)}
                >
                  Historial
                </Button>
              </td>
              <td onClick={() => handleEditClick(index, 'Deposito')} style={{ cursor: 'pointer' }}>
                {editingIndex === index && editType === 'Deposito' ? (
                  <FormControl
                    ref={inputRef}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9\-]*"
                    value={editableValue}
                    onChange={handleValueChange}
                    onKeyDown={(e) => handleValueKeyDown(e, user)}
                    autoFocus
                    placeholder="Ingrese valor"
                  />
                ) : (
                  <span>{user.usuario.valor_depositar.toFixed(2)}</span>
                )}
              </td>
              <td onClick={() => handleEditClick(index, 'Recarga')} style={{ cursor: 'pointer' }}>
                {editingIndex === index && editType === 'Recarga' ? (
                  <FormControl
                    ref={inputRef}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9\-]*"
                    value={editableValue}
                    onChange={handleValueChange}
                    onKeyDown={(e) => handleValueKeyDown(e, user)}
                    autoFocus
                    placeholder="Ingrese valor"
                  />
                ) : (
                  <span>{user.usuario.valor_recargas.toFixed(2)}</span>
                )}
              </td>
              <td onClick={() => handleSaldoClick(index)} style={{ cursor: 'pointer' }}>
                {editingSaldoIndex === index ? (
                  <FormControl
                    ref={inputRef}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9\-]*"
                    value={editableSaldoValue}
                    onChange={handleSaldoChange}
                    onKeyDown={(e) => handleSaldoKeyDown(e, user)}
                    autoFocus
                  />
                ) : (
                  <span>{user.saldo.toFixed(2)}</span>
                )}
              </td>
              <td>
                <ToggleSwitch
                  id={`activo-switch-${index}`}
                  checked={user.usuario.activo}
                  onChange={() => handleToggle(index, 'activo')}
                />
              </td>
              <td>

   

          <Button
                  variant="outline-primary"
                  style={{ padding: '5px 10px', fontSize: '14px' }}
                  onClick={() => handleShowSaldoModal(user)}
                  >
                  Configuración
                </Button>
              
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

 {/* Mobile View Cards */}
<div className="d-md-none user-cards">
  {filteredUsers.map((user, index) => (
    <div key={index} className="user-card">
      {/* Primera fila: Correo y Tiendas */}
      <div className="user-row">
        <div className="user-detail correo">
          <strong>Correo:</strong>{' '}
          <button
            onClick={() => actAsSeller(user)}
            style={{ cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
          >
            {user.usuario.correo}
          </button>
        </div>
        <div className="user-detail tiendas right-align">
          <strong>Tiendas:</strong> {user.usuario.total_tiendas}
        </div>
      </div>

      {/* Segunda fila: Depósitos, Recargas y Saldo */}
      <div className="user-row" onClick={(e) => handleRowClick(e, index)}>
        <div className="user-detail left-align">
          <strong>Depósitos:</strong>
          {/* Lógica para editar Depósitos */}
          {editingIndex === index && editType === 'Deposito' ? (
            <FormControl
              ref={inputRef}
              type="text"
              inputMode="decimal"
              pattern="[0-9\-]*"
              value={editableValue}
              onChange={(e) => handleValueChange(e)}
              onKeyDown={(e) => handleValueKeyDown(e, user)}
              autoFocus
              placeholder="Ingrese valor"
              onBlur={() => setEditingIndex(null)}
            />
          ) : (
            <span onClick={() => handleEditClick(index, 'Deposito')} style={{ cursor: 'pointer' }}>
              {user.usuario.valor_depositar.toFixed(2)}
            </span>
          )}
        </div>
        <div className="user-detail center-align">
          <strong>Recargas:</strong>
          {/* Lógica para editar Recargas */}
          {editingIndex === index && editType === 'Recarga' ? (
            <FormControl
              ref={inputRef}
              type="text"
              inputMode="decimal"
              pattern="[0-9\-]*"
              value={editableValue}
              onChange={(e) => handleValueChange(e)}
              onKeyDown={(e) => handleValueKeyDown(e, user)}
              autoFocus
              placeholder="Ingrese valor"
              onBlur={() => setEditingIndex(null)}
            />
          ) : (
            <span onClick={() => handleEditClick(index, 'Recarga')} style={{ cursor: 'pointer' }}>
              {user.usuario.valor_recargas.toFixed(2)}
            </span>
          )}
        </div>
        <div className="user-detail right-align">
          <strong>Saldo:</strong>
          {/* Lógica para editar Saldo */}
          {editingSaldoIndex === index ? (
            <FormControl
              ref={inputRef}
              type="text"
              inputMode="decimal"
              pattern="[0-9\-]*"
              value={editableSaldoValue}
              onChange={(e) => handleSaldoChange(e)}
              onKeyDown={(e) => handleSaldoKeyDown(e, user)}
              autoFocus
              onBlur={() => setEditingSaldoIndex(null)}
            />
          ) : (
            <span onClick={() => handleSaldoClick(index)} style={{ cursor: 'pointer' }}>
              {user.saldo.toFixed(2)}
            </span>
          )}
        </div>
      </div>

      {/* Tercera fila: Historial, Activo, Configuración */}
      <div className="user-row">
      <div className="user-detail left-align">
          <div className="activo-container">
            <strong>Activo:</strong>
            <ToggleSwitch
              id={`activo-switch-${index}`}
              checked={user.usuario.activo}
              onChange={() => handleToggle(index, 'activo')}
            />
          </div>
        </div>
        <div className="user-detail center-align">
          <Button
            variant="outline-primary"
            style={{ padding: '5px 10px', fontSize: '14px' }}
            onClick={() => navigate(`admin/historial/${user.usuario.id}`)}
          >
            Historial
          </Button>
        </div>
      
        <div className="user-detail right-align">
          <Button
            variant="outline-primary"
            style={{ padding: '5px 10px', fontSize: '14px' }}
            onClick={() => handleShowSaldoModal(user)}
          >
            Configuración
          </Button>
        </div>
      </div>
    </div>
  ))}
</div>


      {/* Modal for Adding New User */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Vendedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formEmail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresa el correo"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (validateEmail(e.target.value)) {
                    setEmailError('');
                  } else {
                    setEmailError('El correo electrónico no es válido');
                  }
                }}
                required
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el nombre"
                value={nombres_apellidos}
                onChange={(e) => {
                  setNombresApellidos(e.target.value);
                  if (validateName(e.target.value)) {
                    setNameError('');
                  } else {
                    setNameError('El nombre solo puede contener letras y espacios');
                  }
                }}
                required
                isInvalid={!!nameError}
              />
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Número de WhatsApp</Form.Label>
              <Row>
                <Col md={4}>
                  <Form.Control
                    as="select"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  >
                    <option value="+52">🇲🇽 +52 México</option>
                    <option value="+1">🇺🇸 +1 USA</option>
                    <option value="+34">🇪🇸 +34 España</option>
                  </Form.Control>
                </Col>
                <Col md={8}>
                  <Form.Control
                    type="tel"
                    placeholder="Ingresa el número de teléfono"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      if (validatePhoneNumber(e.target.value)) {
                        setPhoneError('');
                      } else {
                        setPhoneError('El número debe ser de 10 dígitos y solo números');
                      }
                    }}
                    required
                    isInvalid={!!phoneError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {phoneError}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: '#0A74DA', color: '#fff' }}
            onClick={handleCreateUser}
          >
            Crear Usuario
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal para editar los datos */}
<Modal show={showSaldoModal} onHide={handleCloseSaldoModal}>
  <Modal.Header closeButton>
    <Modal.Title>Editar Datos</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedUser ? (
      <Form>
        <Form.Group controlId="formCorreo">
          <Form.Label>Correo</Form.Label>
          <InputGroup>
          <Form.Control
            type="email"
            value={editedEmail}
            onChange={handleEmailChange}
            isInvalid={!!emailError}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleEmailSubmit();
                e.target.blur();
              }
            }}
          />
                 <Button variant="primary" onClick={handleEmailSubmit}>
                <FaArrowRight />
              </Button>
          <Form.Control.Feedback type="invalid">
            {emailError}
          </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formUsuarioPorcentaje">
          <Form.Label>Porcentaje Usuario (0 - 2)</Form.Label>
          <InputGroup>

          <Form.Control
            type="number"
            value={editedUsuarioPorcentaje}
            onChange={handleUsuarioPorcentajeChange}
            isInvalid={!!usuarioPorcentajeError}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleUsuarioPorcentajeSubmit();
                e.target.blur();
              }
            }}
          />      <Button variant="primary" onClick={handleUsuarioPorcentajeSubmit}>
          <FaArrowRight />
        </Button>
          
          <Form.Control.Feedback type="invalid">
            {usuarioPorcentajeError}
          </Form.Control.Feedback>
          </InputGroup>

        </Form.Group>

        <Form.Group controlId="formPorcentaje">
          <Form.Label>Porcentaje (0 - 7)</Form.Label>
          <InputGroup>
          <Form.Control
            type="number"
            value={editedPorcentaje}
            onChange={handlePorcentajeChange}
            isInvalid={!!porcentajeError}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handlePorcentajeSubmit();
                e.target.blur();
              }
            }}
          />
           <Button variant="primary" onClick={handlePorcentajeSubmit}>
                <FaArrowRight />
              </Button>
          <Form.Control.Feedback type="invalid">
            {porcentajeError}
          </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form>
    ) : (
      <p>Cargando detalles...</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseSaldoModal}>
      Cerrar
    </Button>
  
  </Modal.Footer>
</Modal>


      <style jsx>{`
  .user-cards {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .user-card {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    transition: background-color 0.3s ease;
    align-items: center; /* Centra verticalmente los elementos */

  }
 .user-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    align-items: center; /* Centra verticalmente los elementos */
  }
  .user-detail {
    flex: 1;
  }
  .left-align {
    text-align: left;
  }
  .center-align {
    text-align: center;
  }
  .right-align {
    text-align: right;
  }
    .user-detail.correo {
    flex: 2; /* 'Correo' ocupa 2 partes del espacio */
  }
  .user-detail.tiendas {
    flex: 1; /* 'Tiendas' ocupa 1 parte del espacio */
  }
    .activo-container {
  display: flex;
    flex-direction: row;
    align-items: left;
  }
    .activo-container .switch {
  margin-top: 3px; /* Ajusta este valor según sea necesario */
}
`}</style>
    </Container>
  );
};

export default ManageSellers;
