import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importamos los íconos de FontAwesome
import api from '../../services/axiosConfig';

const Configuracion = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [storeName, setStoreName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));

      setPhone(payload.celular || '');
      setStoreName(payload.nombre_tienda || '');
      setEmail(payload.correo || '');
      setIsEmailEditable(!payload.correo);
    }
  }, []);

  const handleEmailSubmit = () => {
    if (email && validateEmail(email)) {
      const token = localStorage.getItem('token');
      api.put('/auth/editar-correo', { correo: email }, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          alert('Correo actualizado correctamente');
          const newToken = response.data.token;
          localStorage.setItem('token', newToken); // Guardar el nuevo token en localStorage
          window.location.reload(); // Recargar la página
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error || 'Error al actualizar correo';
          alert(errorMessage);
        });
    } else {
      alert('Por favor ingresa un correo válido.');
    }
  };
  
  

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlePasswordSubmit = () => {
    if (newPassword === confirmPassword) {
      const token = localStorage.getItem('token');
  
      api.put('/api/auth/editar-contrasenia', { nueva_contrasenia: newPassword }, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          alert('Contraseña actualizada correctamente');
          const newToken = response.data.token;
          console.log(newToken);
          localStorage.setItem('token', newToken); // Guardar el nuevo token en localStorage
           window.location.reload(); // Recargar la página
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error || 'Error al actualizar contraseña';
          alert(errorMessage);
        });
    } else {
      alert('Las contraseñas no coinciden');
    }
  };
  
  
  // Función para bloquear copiar, pegar y cortar
  const blockCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-sm p-4">
            <Card.Body>
              <h3 className="text-center mb-4">Configuración de la Tienda</h3>
              <Form>
                {/* Nombre de la tienda (solo lectura) */}
                <Form.Group controlId="storeName">
                  <Form.Label>Nombre de la Tienda</Form.Label>
                  <Form.Control type="text" value={storeName} readOnly className="form-control-lg" />
                </Form.Group>

                {/* Teléfono (solo lectura) */}
                <Form.Group controlId="phone">
                  <Form.Label>Número de Teléfono</Form.Label>
                  <Form.Control type="text" value={phone} readOnly className="form-control-lg" />
                </Form.Group>

                {/* Correo electrónico (editable solo si está vacío) */}
                <Form.Group controlId="email">
                  <Form.Label>Correo Electrónico</Form.Label>
                  {isEmailEditable ? (
                    <InputGroup>
                      <Form.Control
                        type="email"
                        placeholder="Ingresa tu correo electrónico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control-lg"
                      />
                      <Button variant="primary" onClick={handleEmailSubmit} className="btn-lg">
                        Enviar
                      </Button>
                    </InputGroup>
                  ) : (
                    <Form.Control type="email" value={email} readOnly className="form-control-lg" />
                  )}
                </Form.Group>

                {/* Nueva contraseña */}
                <Form.Group controlId="newPassword">
                  <Form.Label>Nueva Contraseña</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showNewPassword ? 'text' : 'password'}
                      placeholder="Ingresa nueva contraseña"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control-lg"
                      onCopy={blockCopyPaste} // Bloquea copiar
                      onPaste={blockCopyPaste} // Bloquea pegar
                      onCut={blockCopyPaste}   // Bloquea cortar
                    />
                    <Button
                      variant="outline-secondary"
                      onMouseDown={() => setShowNewPassword(true)}
                      onMouseUp={() => setShowNewPassword(false)}
                      className="btn-lg"
                    >
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                </Form.Group>

                {/* Confirmar contraseña */}
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirmar Contraseña</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirma tu nueva contraseña"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control-lg"
                      onCopy={blockCopyPaste} // Bloquea copiar
                      onPaste={blockCopyPaste} // Bloquea pegar
                      onCut={blockCopyPaste}   // Bloquea cortar
                    />
                    <Button
                      variant="outline-secondary"
                      onMouseDown={() => setShowConfirmPassword(true)}
                      onMouseUp={() => setShowConfirmPassword(false)}
                      className="btn-lg"
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Button variant="success" onClick={handlePasswordSubmit} className="btn-lg w-100 mt-4">
                  Cambiar Contraseña
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Configuracion;
